
import React, {useState} from "react"
import {Link} from "gatsby"

import * as styles from "./menupage.module.css"

import imgmenusearch from "../../../images/menu-search.png"
import imgmenuclose from "../../../images/menu-close.png"

const SEARCHMENUMINIMUM=3;


const WebappsMenupageComponent = ({title, menusrc, urlprefix}) => {
	const [searchkey, setSearchkey] = useState("");

	const searchKeyChange = (e) => {
		setSearchkey(e.target.value.toLowerCase());
	}

	const renderparent = (key, entry, cururlprefix) => {
		const showsection = (entry.title.toLowerCase().indexOf(searchkey)>=0 && !entry.subitems) || (entry.subitems &&JSON.stringify(entry.subitems).toLowerCase().indexOf(searchkey)>=0);
		if (!showsection) {
			return <></>
		}
		if (entry.hasOwnProperty("subitems")) {
			return <div className={styles.menupagecontentsublist}>
				<h2>{entry.title}</h2>
				<hr/>
				{entry.subitems.map((subentry, idx)=> {return renderparent(key+"-"+idx, subentry, cururlprefix)})}
				<hr/>
			</div>
		} else {
			return <Link
					to={cururlprefix+entry.url}
				>
					{entry.title}
				</Link>

		}
	}

	var linkurlprefix = typeof urlprefix !== "undefined"?urlprefix:"/";
	var curmenu = menusrc;

	var totalidx = 0;
	var primaryidx = 0;
	var secondaryidx = 0;
	while (primaryidx < menusrc.length) {
		if (menusrc[primaryidx].hasOwnProperty("subitems")) {
			secondaryidx = 0;
			while (secondaryidx < menusrc[primaryidx].subitems.length) {
				totalidx++;
				secondaryidx++;
			}
		} else {
			totalidx++;
		}
		primaryidx++;
	}

	if (linkurlprefix.length < 1) {
		linkurlprefix = "/";
	} else {
		if (linkurlprefix.substring(0,1)!=="/") {
			linkurlprefix = "/"+linkurlprefix;
		}
		if (linkurlprefix.substring(linkurlprefix.length - 1) !== "/") {
			linkurlprefix = linkurlprefix + "/";
		}
	}

	return <>
		<h1 className={styles.menupagewelcome+" font-size-medium text-centered"}>{title}</h1>
		{(totalidx > SEARCHMENUMINIMUM) && <div className={styles.menupagesearchholder+" text-centered"}>
			<div className={styles.menupagesearch}>
				<input type={"text"} placeholder={"Search "+title} value={searchkey} onChange={searchKeyChange} onBlur={searchKeyChange} />
				{searchkey !== "" &&
					<button title={"Clear"} className={styles.menupagesearchclear+" iconbutton"} onClick={(e)=>{setSearchkey("");}}>
						<img src={imgmenuclose} alt={"Clear"} />
					</button>
				}
				<button title={"Search"} className={"iconbutton"}>
					<img src={imgmenusearch} alt={"Search"} />
				</button>
			</div>
		</div>}
		<div className={styles.menupagecontent+" margin-content"}>
			{curmenu.map((entry, idx)=> {
				return renderparent("main"+idx, entry, linkurlprefix);
			})}
		</div>
	</>
}


export default WebappsMenupageComponent;
